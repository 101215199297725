import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  MapWrapper,
  Grid,
  SectionWrapper,
  Title,
  List,
  ListRow,
  ListItem,
  DescriptionTitle,
  Description,
  HorizontalDivider,
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import Loader from "../../../../../common/Loader";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import { PageTitle } from "../../../../../common/PageTitle";

const HistoryMap = React.lazy(() => import("../Map"));

interface HistoryProps {
  pageChange: PageChange;
}

const History: React.FC<HistoryProps> = ({ pageChange }) => {
  const { t } = useTranslation("main");

  return (
    <>
      <PageTitle subtitle={t(`history_title`)} />
      <PageWrapper animation={pageChange}>
        <Grid>
          <SectionWrapper>
            {t(`history_firstTitle`)
              .split("\n")
              .map((text, index) => (
                <Title color={index === 0 ? "#151515" : "#0500FF"} key={index}>
                  {text}
                </Title>
              ))}
          </SectionWrapper>
          <SectionWrapper>
            {t(`history_secondTitle`)
              .split("\n")
              .map((text, index, arr) => (
                <Title
                  color={index === arr.length - 1 ? "#0500FF" : "#151515"}
                  key={index}
                >
                  {text}
                </Title>
              ))}
          </SectionWrapper>
          <HorizontalDivider />
          <List>
            <tbody>
              <ListRow>
                <ListItem>{t(`transactionSystems`)}</ListItem>
              </ListRow>
              <ListRow>
                <ListItem>{t(`provisioningSystems`)}</ListItem>
              </ListRow>
              <ListRow>
                <ListItem>{t(`hybridTV`)}</ListItem>
              </ListRow>
              <ListRow>
                <ListItem>{t(`mobileApps`)}</ListItem>
              </ListRow>
              <ListRow>
                <ListItem>{t(`smartCity`)}</ListItem>
              </ListRow>
            </tbody>
          </List>
          <Description
            dangerouslySetInnerHTML={{
              __html: fixConjunctions(t(`history_others`)),
            }}
          />
          <HorizontalDivider />
          <Description>
            <DescriptionTitle>
              {t(`history_academicCollaboration_title`)}
            </DescriptionTitle>
            {t(`history_academicCollaboration_description`)}
          </Description>
          <Description>
            <DescriptionTitle>
              {t(`history_businessModels_title`)}
            </DescriptionTitle>
            {t(`history_businessModels_description`)}
          </Description>
          <Description>
            <DescriptionTitle>{t(`history_employees_title`)}</DescriptionTitle>
            {t(`history_employees_description`)}
          </Description>
        </Grid>
        <MapWrapper>
          <Suspense fallback={<Loader width={100} height={100} />}>
            <HistoryMap />
          </Suspense>
        </MapWrapper>
      </PageWrapper>
    </>
  );
};

export default History;
