import styled, { css } from "styled-components";

import { device } from "../../../../../../utils/breakpoints";
import {
  fadeIn,
  scaleUp,
  slideToTop,
} from "../../../../../../utils/animations";
import {
  H2,
  H3,
  TEXT_BODY_2,
  TEXT_BUTTON_2,
} from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${slideToTop} 300ms ease-in
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;

  .transitionWrapper {
    display: ${(props: PageProps) => (props.animation ? "grid" : "none")};
  }

  @media ${device.computer} {
    padding: 128px 120px 120px 120px;
  }

  @media ${device.desktop} {
    padding: 128px 120px 120px 120px;
  } ;
`;

export const MapWrapper = styled.div`
  width: 100%;
  min-width: 0;
  min-height: 0;
  z-index: -1;

  @media ${device.computer} {
    position: absolute;
    top: 0;
    right: 0;
    width: 60%;
    height: 100%;
  }

  @media ${device.desktop} {
    width: 70%;
  } ;
`;

export const Grid = styled.div`
  width: 90%;
  height: 100%;
  padding: 88px 100px 40px 88px;
  display: grid;
  grid-template-columns: calc(50% - 40px) 80px calc(50% - 40px);
  grid-template-rows: repeat(auto, 6);

  @media ${device.tabletLandscape} {
    width: 74%;
  }

  @media ${device.computer} {
    grid-template-columns: repeat(12, 64px);
    grid-column-gap: 32px;
    padding: 0;
  }

  @media ${device.desktop} {
    grid-template-columns: repeat(12, 110px);
  } ;
`;

export const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  &:first-of-type {
    grid-column: 1;
  }

  &:not(:first-of-type) {
    grid-column: 3;
  }

  @media ${device.computer} {
    &:first-of-type {
      grid-column-start: 1;
      grid-column-end: 5;
    }

    &:not(:first-of-type) {
      grid-column-start: 5;
      grid-column-end: 9;
    }
  }

  @media ${device.desktop} {
    justify-content: flex-start;
    flex-direction: row;
  } ;
`;

interface TitleProps extends PageProps {
  color?: string;
}

export const Title = styled.h2`
  width: 100%;
  margin: 0;
  font-size: 24px;
  line-height: 28px;
  font-weight: 800;
  color: ${(props: TitleProps) => props.color || "#151515"};
  text-transform: uppercase;
  opacity: 0;
  animation: ${fadeIn} 300ms linear;
  animation-fill-mode: forwards;
  animation-delay: 1s;

  @media ${device.computer} {
    ${H3}
  }

  @media ${device.desktop} {
    ${H2}
  } ;
`;

export const List = styled.table`
  width: 100%;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  opacity: 0;
  animation: ${fadeIn} 300ms linear;
  animation-fill-mode: forwards;
  animation-delay: 1.6s;

  @media ${device.computer} {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  @media ${device.desktop} {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

export const ListRow = styled.tr`
  list-style: none;

  &:not(:last-of-type) > td {
    padding-bottom: 4px;
  }
`;

export const ListItem = styled.td`
  ${TEXT_BUTTON_2}
  padding: 0;
  color: #151515;
  text-transform: uppercase;
  border: none;
`;

export const DescriptionTitle = styled.span`
  ${TEXT_BUTTON_2}

  @media ${device.computer} {
    padding-bottom: 8px;
  }
`;

export const Description = styled.span`
  ${TEXT_BODY_2}
  grid-column: 1;
  padding-bottom: 32px;
  color: #151515;
  white-space: pre-line;
  opacity: 0;
  animation: ${fadeIn} 300ms linear;
  animation-fill-mode: forwards;
  animation-delay: 1.9s;

  b {
    margin-bottom: 8px;
  }

  &:nth-of-type(2) {
    animation-delay: 2.2s;
  }

  &:nth-of-type(3) {
    animation-delay: 2.5s;
  }

  &:nth-of-type(4) {
    animation-delay: 2.8s;
  }

  &:nth-of-type(5) {
    animation-delay: 3.1s;
  }

  &:nth-of-type(2n + 1) {
    grid-column: 3;

    @media ${device.laptopL} {
      grid-column: 3 / span 3;
    }

    @media ${device.desktop} {
      grid-column: 3;
    }
  }

  @media ${device.computer} {
    padding-bottom: 56px;

    &:nth-of-type(1) {
      padding-bottom: 0;
      grid-column-start: 5;
      grid-column-end: 8;
    }

    &:nth-of-type(2) {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    &:nth-of-type(3) {
      padding-bottom: 0;
      grid-column-start: 5;
      grid-column-end: 8;
    }

    &:nth-of-type(4) {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    &:nth-of-type(5) {
      padding-bottom: 0;
      grid-column-start: 5;
      grid-column-end: 8;
    }
  }

  @media ${device.desktop} {
    &:nth-of-type(1) {
      grid-column-start: 3;
      grid-column-end: 5;
    }

    &:nth-of-type(2) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    &:nth-of-type(3) {
      grid-column-start: 3;
      grid-column-end: 5;
    }

    &:nth-of-type(4) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    &:nth-of-type(5) {
      grid-column-start: 3;
      grid-column-end: 5;
    }
  }
`;

export const HorizontalDivider = styled.hr`
  width: 100%;
  min-height: 2px;
  margin: 26px 0;
  background: #eaeaea;
  grid-column: 1 / span 6;
  transform: scale(0);
  transform-origin: 0 50%;
  animation: ${scaleUp} 300ms ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1s;

  &:last-of-type {
    animation-delay: 2s;
  }

  @media ${device.computer} {
    grid-column-start: 1;
    grid-column-end: 8;
    margin: 56px 0 56px 0;

    &:first-of-type {
      margin: 41px 0 56px 0;
    }
  }

  @media ${device.desktop} {
    grid-column-end: 5;
  } ;
`;
